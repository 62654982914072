import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../common/error/error'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HelmetComponent from '../common/helmet/helmet.component'
import SvgIcons from '../simple/SvgIcons'
import { ToastContainer } from 'react-toastify'

const Layout = () => {
  const { t } = useTranslation()
  const helmetData = {
    title: t('OnBoarding.HelmetDefaultTitle'),
    meta: [
      {
        name: 'description',
        content: t('OnBoarding.HelmetDefaultDescription')
      },
      {
        property: 'og:title',
        content: t('OnBoarding.HelmetOGTitle')
      },
      {
        property: 'og:site_name',
        content: `OnBoarding.lb.ge`
      },
      {
        property: 'og:url',
        content: `https://onboarding.lb.ge`
      },
      {
        property: 'og:description',
        content: t('OnBoarding.HelmetDefaultDescription')
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:image',
        content: t('OnBoarding.HelmetOgLogoLink')
      }
    ]
  }
  return (
    <div className="nob-container-layout">
      <HelmetComponent {...helmetData} />
      <SvgIcons />
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ErrorFallback
            error={error}
            resetErrorBoundary={resetErrorBoundary}
          />
        )}
      >
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          limit={3}
        />
        <Outlet />
      </ErrorBoundary>
    </div>
  )
}
export default Layout
