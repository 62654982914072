import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'
import logger from 'redux-logger'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist'
import authSlice from './slices/auth.slice'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { onBoardingApi } from './api/on-boarding.api'
import helperSlice from './slices/helper.slice.'
import campaignSlice from './slices/campaign.slice'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  blacklist: ['campaign']
}
const persistCampaignConfig = {
  key: 'campaign',
  storage: storageSession
}
const rootReducer = combineReducers({
  [onBoardingApi.reducerPath]: onBoardingApi.reducer,
  auth: authSlice,
  helper: helperSlice,
  campaign: persistReducer(persistCampaignConfig, campaignSlice)
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'development'
      ? getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
          }
        })
          .concat(logger)
          .concat(onBoardingApi.middleware)
      : getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
          }
        }).concat(onBoardingApi.middleware)
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)
