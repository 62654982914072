import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { logOut, setTokens } from '../slices/auth.slice'
import { selectAccessToken, selectAuth } from '../selectors/auth.selector'
import i18n from '../../i18n'
import LbToast from '../../components/common/toast/lb-toast'

const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
  baseUrl: window?.configs?.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = selectAccessToken(getState())
    headers.set('accept', 'application/json')

    headers.set('Accept-Language', i18n.language)
    headers.set('Authorization', `Bearer ${token}`)
    return headers
  }
})

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery(
          {
            url: 'authentication/refresh-tokens',
            method: 'POST',
            body: selectAuth(api.getState())
          },
          api,
          extraOptions
        )
        if (refreshResult?.data) {
          api.dispatch(setTokens(refreshResult.data))
          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logOut())
          api.endpoint !== 'logOut' &&
            LbToast('error', i18n.t('OnBoarding.LoggedOutFromSystem'))
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}
export default baseQueryWithReAuth
