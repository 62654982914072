import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReAuth from './custom-base-query'

export const onBoardingApi = createApi({
  reducerPath: 'onBoardingApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['App'],
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: () => `references/countries`,
      transformResponse: (baseQueryReturnValue) => {
        //response should be arranged alphabetically
        return baseQueryReturnValue.sort(function (a, b) {
          if (a.id === 268) return -1
          if (b.id === 268) return 1
          return a.value.localeCompare(b.value)
        })
      }
    }),
    getCitizenshipCountries: builder.query({
      query: () => `references/countries-for-citizenship`,
      transformResponse: (baseQueryReturnValue) => {
        //response should be arranged alphabetically
        return baseQueryReturnValue.sort(function (a, b) {
          if (a.id === 0) return -1
          if (b.id === 0) return 1
          return a.value.localeCompare(b.value)
        })
      }
    }),
    getIncomeLevels: builder.query({
      query: () => `references/income-levels`
    }),
    getJobStatuses: builder.query({
      query: () => `references/job-statuses`
    }),
    getPhonePrefixes: builder.query({
      query: () => `references/phone-meta-datas`
    }),
    getProductDeliveryTypes: builder.query({
      query: () => `references/product-delivery-types`
    }),
    getRegReasons: builder.query({
      query: () => `references/registration-reasons`
    }),
    getJobActivities: builder.query({
      query: () => `/references/job-activities`
    }),
    getRevenueExps: builder.query({
      query: () => `references/revenue-exps`
    }),
    getWealthSources: builder.query({
      query: () => `references/wealth-sources`
    }),
    getAppData: builder.query({
      query: () => `application`,
      providesTags: ['App']
    }),
    checkAmlStatus: builder.query({
      query: () => `application/check-aml-status`
    }),
    startIdProcess: builder.mutation({
      query: (body) => {
        return {
          url: `application/start`,
          method: 'POST',
          body
        }
      }
    }),
    logOut: builder.mutation({
      query: () => {
        return {
          url: `authentication/cancel`,
          method: 'POST'
        }
      }
    }),
    finishIdProcess: builder.mutation({
      query: () => {
        return {
          url: `application/finish-identification`,
          method: 'POST'
        }
      }
    }),
    agreeOnTerms: builder.mutation({
      query: () => {
        return {
          url: `application/agree-on-terms`,
          method: 'POST'
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getAppData',
              undefined,
              (draft) => {
                Object.assign(draft, data)
              }
            )
          )
          // eslint-disable-next-line no-empty
        } catch {}
      }
    }),
    uploadDocs: builder.mutation({
      query: ({ formData }) => {
        return {
          url: `application/upload-kyc-document`,
          method: 'POST',
          body: formData
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: uploadedFile } = await queryFulfilled
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getAppData',
              undefined,
              (draft) => {
                draft.kycDocuments.push(uploadedFile)
              }
            )
          )
          // eslint-disable-next-line no-empty
        } catch {}
      }
    }),
    sendEmailCode: builder.mutation({
      query: (body) => {
        return {
          url: `application/send-email-code`,
          method: 'POST',
          body
        }
      }
    }),
    deleteDocuments: builder.mutation({
      query: ({ id }) => {
        return {
          url: `application/delete-kyc-document?Id=${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['App']
    }),
    sendSmsCode: builder.mutation({
      query: (body) => {
        return {
          url: `application/send-sms-code`,
          method: 'POST',
          body
        }
      }
    }),
    confirmEmail: builder.mutation({
      query: (body) => {
        return {
          url: `application/confirm-email`,
          method: 'POST',
          body
        }
      }
    }),
    confirmApp: builder.mutation({
      query: (body) => {
        return {
          url: `application/confirm`,
          method: 'POST',
          body
        }
      }
    }),
    updatePersonalInfo: builder.mutation({
      query: (patch) => ({
        url: `application/kyc-personal-info`,
        method: 'PUT',
        body: patch
      }),
      // invalidatesTags: ['App']

      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInfo } = await queryFulfilled
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycPersonalInfo: patch,
                  ...updatedInfo
                })
              }
            )
          )
        } catch {
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycPersonalInfo: patch
                })
              }
            )
          )
        }
      }
    }),
    updateActivityInfo: builder.mutation({
      query: (patch) => ({
        url: `application/kyc-activity`,
        method: 'PUT',
        body: patch
      }),
      // invalidatesTags: ['App']
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInfo } = await queryFulfilled
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycActivity: patch,
                  ...updatedInfo
                })
              }
            )
          )
        } catch {
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycActivity: patch
                })
              }
            )
          )
        }
      }
    }),
    updateAdditionalInfo: builder.mutation({
      query: (patch) => ({
        url: `application/kyc-additional-info`,
        method: 'PUT',
        body: patch
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInfo } = await queryFulfilled
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycAdditionalInfo: patch,
                  ...updatedInfo
                })
              }
            )
          )
        } catch {
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycAdditionalInfo: patch
                })
              }
            )
          )
        }
      }
    }),
    //residents api
    finishResidentIdProcess: builder.mutation({
      query: () => {
        return {
          url: `residents/application/finish-identification`,
          method: 'POST'
        }
      }
    }),
    checkResidentAmlStatus: builder.query({
      query: () => `residents/application/check-aml-status`
    }),

    getApplicationSteps: builder.query({
      query: () => `residents/application-steps`
    }),
    getResidentAppData: builder.query({
      query: () => `residents/application`,
      providesTags: ['App']
    }),
    residentLogOut: builder.mutation({
      query: () => {
        return {
          url: `authentication/cancel`,
          method: 'POST'
        }
      }
    }),
    updateResidentPersonalInfo: builder.mutation({
      query: (patch) => ({
        url: `residents/kyc-personal-info`,
        method: 'PUT',
        body: patch
      }),
      // invalidatesTags: ['App']

      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInfo } = await queryFulfilled
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getResidentAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycPersonalInfo: patch,
                  ...updatedInfo
                })
              }
            )
          )
        } catch {
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getResidentAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycPersonalInfo: patch
                })
              }
            )
          )
        }
      }
    }),
    updateResidentActivityInfo: builder.mutation({
      query: (patch) => ({
        url: `residents/kyc-activity`,
        method: 'PUT',
        body: patch
      }),
      // invalidatesTags: ['App']
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInfo } = await queryFulfilled
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getResidentAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycActivity: patch,
                  ...updatedInfo
                })
              }
            )
          )
        } catch {
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getResidentAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycActivity: patch
                })
              }
            )
          )
        }
      }
    }),
    updateResidentAdditionalInfo: builder.mutation({
      query: (patch) => ({
        url: `residents/kyc-additionalinfo`,
        method: 'PUT',
        body: patch
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInfo } = await queryFulfilled
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getResidentAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycAdditionalInfo: patch,
                  ...updatedInfo
                })
              }
            )
          )
        } catch {
          dispatch(
            onBoardingApi.util.updateQueryData(
              'getResidentAppData',
              undefined,
              (draft) => {
                Object.assign(draft, {
                  kycAdditionalInfo: patch
                })
              }
            )
          )
        }
      }
    }),
    reserveBankUser: builder.mutation({
      query: (body) => {
        return {
          url: `residents/reserve-ibuser`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags: ['App']
    }),
    //confirm Sms code mutation
    confirmSmsCode: builder.mutation({
      query: (body) => {
        return {
          url: `residents/confirm`,
          method: 'POST',
          body
        }
      }
    }),
    checkResidentUserName: builder.mutation({
      query: (userName) => {
        return {
          url: `residents/check-username`,
          method: 'POST',
          body: { username: userName }
        }
      }
    }),
    sendResidentSmsCode: builder.mutation({
      query: (body) => {
        return {
          url: `residents/send-sms-code`,
          method: 'POST',
          body
        }
      }
    })
  })
})
export const {
  useGetCountriesQuery,
  useGetCitizenshipCountriesQuery,
  useGetIncomeLevelsQuery,
  useGetJobStatusesQuery,
  useGetPhonePrefixesQuery,
  useGetProductDeliveryTypesQuery,
  useGetRegReasonsQuery,
  useGetRevenueExpsQuery,
  useGetWealthSourcesQuery,
  useGetJobActivitiesQuery,
  useStartIdProcessMutation,
  useLogOutMutation,
  useFinishIdProcessMutation,
  useGetAppDataQuery,
  useUpdateAdditionalInfoMutation,
  useAgreeOnTermsMutation,
  useUpdatePersonalInfoMutation,
  useUpdateActivityInfoMutation,
  useSendEmailCodeMutation,
  useSendSmsCodeMutation,
  useConfirmEmailMutation,
  useConfirmAppMutation,
  useCheckAmlStatusQuery,
  useUploadDocsMutation,
  useDeleteDocumentsMutation,
  useFinishResidentIdProcessMutation,
  useGetResidentAppDataQuery,
  useCheckResidentAmlStatusQuery,
  useGetApplicationStepsQuery,
  useResidentLogOutMutation,
  useUpdateResidentPersonalInfoMutation,
  useUpdateResidentActivityInfoMutation,
  useUpdateResidentAdditionalInfoMutation,
  useReserveBankUserMutation,
  useConfirmSmsCodeMutation,
  useSendResidentSmsCodeMutation,
  useCheckResidentUserNameMutation,
  useGetDisclaimerTextsQuery
} = onBoardingApi
