import { createSelector } from '@reduxjs/toolkit'

export const selectAuth = (state) => state?.auth

export const selectAccessToken = createSelector(
  [selectAuth],
  (auth) => auth?.accessToken
)
export const selectRefreshToken = createSelector(
  [selectAuth],
  (auth) => auth?.refreshToken
)
