import LogoMainGeo from '../../../assets/images/logo.svg'
import LogoMainEng from '../../../assets/images/logo-eng.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ErrorFallback = ({ resetErrorBoundary }) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className="nob-container">
      {/* right side */}
      <div className="nob-right__side">
        <div className="nob-header-top">
          <a href="/">
            <img
              src={i18n.language === 'ka' ? LogoMainGeo : LogoMainEng}
              alt="Liberty"
              className="logo"
            />
          </a>
        </div>
        <div className="nob-action-block">
          <div className="pb-24 text-center">
            <i className="icn icn-bg">
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Organization / Status / error / 32</title>
                <g
                  id="Icons"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Organization-/-Status-/-error-/-32"
                    fill="currentcolor"
                    fillRule="nonzero"
                  >
                    <g id="Icon-2" transform="translate(2.000000, 2.000000)">
                      <path
                        d="M-8.8817842e-16,14 L-8.8817842e-16,14 C-8.8817842e-16,21.7319865 6.2680135,28 14,28 C21.7319865,28 28,21.7319865 28,14 C28,6.2680135 21.7319865,-8.8817842e-16 14,-8.8817842e-16 C6.2680135,-8.8817842e-16 -8.8817842e-16,6.2680135 -8.8817842e-16,14 Z M23.15,21.75 L6.25,4.85 C11.0181632,0.881079576 18.0255183,1.20091802 22.4123001,5.58769987 C26.799082,9.97448172 27.1189204,16.9818368 23.15,21.75 Z M6.24,23.16 C1.19784546,18.878188 0.571647205,11.3235533 4.84,6.27 L21.73,23.16 C17.2580147,26.9387089 10.7119853,26.9387089 6.24,23.16 Z"
                        id="Shape"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </i>
          </div>
          <div className="text-center">
            <h1 className="productive-heading-03 pb-12">
              The application could not be sent
            </h1>
            <div className="body-long-02  text-02 ">
              In case of questions, contact us on the number: +995 32 2 55 55 00
            </div>
          </div>

          <button
            className="btn btn-tertiary btn-lg miw-220 mt-48 flex"
            onClick={() => {
              resetErrorBoundary()
              navigate('/')
            }}
          >
            Home
          </button>
        </div>
      </div>
    </div>
  )
}
export default ErrorFallback
