import { createSlice } from '@reduxjs/toolkit'

const initialState = { campaignId: '' }

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignId(state, action) {
      state.campaignId = action.payload
    }
  }
})

export const { setCampaignId } = campaignSlice.actions
export default campaignSlice.reducer
