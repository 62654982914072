import { LbLoader } from './components/simple/LbLoader'
import { lazy, Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from 'react-redux'
import { setCampaignId } from './redux/slices/campaign.slice'
import Layout from './components/layout/layout'

const Protected = lazy(() => import('./components/protected/protected'))
const ResidentProtected = lazy(() =>
  import('./components/protected/resident-protected')
)

const Page404 = lazy(() => import('./pages/404/404'))
const PersonalInfo = lazy(() => import('./pages/personal-info/personal-info'))
const Landing = lazy(() => import('./pages/landing/landing'))
const Identification = lazy(() =>
  import('./pages/identification/identification')
)
const Completed = lazy(() => import('./pages/completed/completed'))
const Failed = lazy(() => import('./pages/failed/failed'))
const Callback = lazy(() => import('./pages/callback/callback'))
const Processing = lazy(() => import('./pages/processing/processing'))
const Activity = lazy(() => import('./pages/activity/activity'))
const Confirmation = lazy(() => import('./pages/confirmation/confirmation'))
const AdditionalInfo = lazy(() =>
  import('./pages/additional-info/additional-info')
)
const PersonalInfoRes = lazy(() =>
  import('./resident-pages/personal-info/personal-info')
)
const ActivityRes = lazy(() => import('./resident-pages/activity/activity'))
const AdditionalInfoRes = lazy(() =>
  import('./resident-pages/additional-info/additional-info')
)
const Registration = lazy(() =>
  import('./resident-pages/registration/registration')
)
const CompletedRes = lazy(() => import('./resident-pages/completed/completed'))
const Disclaimers = lazy(() =>
  import('./pages/terms-and-conditions/disclaimers')
)

function App() {
  const [params] = useSearchParams()
  const campaignId = params?.get('campaignId')
  const dispatch = useDispatch()
  useEffect(() => {
    if (campaignId) {
      dispatch(setCampaignId(campaignId))
    }
  }, [campaignId, dispatch])

  return (
    <Suspense fallback={<LbLoader inCenter={true} isLoading={true} />}>
      <Routes>
        <Route element={<Layout />}>
          <Route path={'*'} element={<Navigate to={'404'} replace={true} />} />
          <Route element={<Landing />} path="/" />
          <Route path={'404'} element={<Page404 />} />
          <Route index element={<Identification />} path={'identification'} />
          {/*residents*/}
          <Route element={<ResidentProtected />}>
            <Route
              path={'resident/personal-info'}
              element={<PersonalInfoRes />}
            />
            <Route path={'resident/activity'} element={<ActivityRes />} />
            <Route
              path={'resident/additional-info'}
              element={<AdditionalInfoRes />}
            />
            <Route path={'resident/registration'} element={<Registration />} />
          </Route>
          {/*non-residents*/}
          <Route element={<Protected />}>
            <Route path={'personal-info'} element={<PersonalInfo />} />
            <Route path={'additional-info'} element={<AdditionalInfo />} />
            <Route path={'confirmation'} element={<Confirmation />} />
            <Route path={'activity'} element={<Activity />} />
          </Route>
          <Route path={'processing'} element={<Processing />} />
          <Route path={'resident/processing'} element={<Processing />} />
          <Route path={'disclaimers'} element={<Disclaimers />} />
          <Route path={'resident/completed'} element={<CompletedRes />} />
          <Route path={'completed'} element={<Completed />} />
          <Route path={'failed'} element={<Failed />} />
          <Route path={'callback'} element={<Callback />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default App
