import { createSlice } from '@reduxjs/toolkit'

const initialState = { acceptedPopupTerms: false, acceptedAgreementTerm: false }

const helperSlice = createSlice({
  name: 'helper',
  initialState,
  reducers: {
    setAcceptedPopupTerms(state, action) {
      state.acceptedPopupTerms = action.payload
    },
    setAcceptedAgreementTerm(state, action) {
      state.acceptedAgreementTerm = action.payload
    }
  }
})

export const { setAcceptedPopupTerms, setAcceptedAgreementTerm } =
  helperSlice.actions
export default helperSlice.reducer
