import { createSlice } from '@reduxjs/toolkit'

const initialState = { accessToken: '', refreshToken: '' }

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens(state, action) {
      state.accessToken = action?.payload?.accessToken
      state.refreshToken = action?.payload?.refreshToken
    },
    logOut(state) {
      state.accessToken = ''
      state.refreshToken = ''
    }
  }
})

export const { setTokens, logOut } = authSlice.actions
export default authSlice.reducer
