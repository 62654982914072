import { toast } from 'react-toastify'

const LbToast = (type, content, options) => {
  let icon
  switch (type) {
    case 'error':
      icon = (
        <i className="icn icn-status">
          {/*<Icon name="icn-circle-close" view="0 0 20 20" />*/}
        </i>
      )
      break
    case 'warning':
      icon = (
        <i className="icn icn-status">
          {/*<Icon name="icn-warr-notif" viewBox="0 0 37.238 34.421" />*/}
        </i>
      )
      break
    default:
      icon = (
        <i className="icn icn-status">
          {/*<Icon name="icn-circle-tick" view="0 0 17.5 17.5" />*/}
        </i>
      )
  }
  // let component = (
  //   <div id="global-notification" className="global-notification">
  //     <div className="flex-aligned">
  //       {icon}
  //       <div className="notif-text flex-1">{content}</div>
  //     </div>
  //   </div>
  // )
  return toast(content, {
    type: type,
    position: 'bottom-left',
    theme: 'colored',
    ...options
  })
}

export default LbToast
